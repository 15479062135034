import { config } from "../../constants/config";
import { Response } from "../../types";
import {
  LogInPayload,
  LogInResponse,
  SignOnPayload,
  SignOnResponse,
} from "./types";

export const userTokenKey = "access_token";

export async function signOn(payload: SignOnPayload): Promise<SignOnResponse> {
  const response = await fetch(`${config.API_URL}/sms/initiate-verification`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });

  const { data }: Response<SignOnResponse> = await response.json();

  // If the response is not 200, throw an error
  if (data.status !== 200) {
    switch (data.status) {
      case 429: {
        throw new Error(
          "You have exceeded the maximum number of attempts to verify your phone number. Please try again later."
        );
      }
      default:
        break;
    }

    switch (data.code) {
      default: {
        console.error(new Error("An unknown error occurred while signing up"));
        throw new Error(
          "There was a problem initiating the verification process. If the problem persists, please contact us."
        );
      }
    }
  }

  return data;
}

export async function logIn(payload: LogInPayload): Promise<LogInResponse> {
  const response = await fetch(`${config.API_URL}/auth/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });

  switch (response.status) {
    case 401: {
      throw new Error("The verification code you provided is incorrect.");
    }
    case 429: {
      throw new Error(
        "You have exceeded the maximum number of attempts to verify your phone number. Please try again later."
      );
    }
    default:
      break;
  }

  try {
    const { data }: Response<LogInResponse> = await response.json();

    switch (data.statusCode) {
      case 500: {
        throw new Error(
          "There was a problem verifying your phone number. If the problem persists, please contact us."
        );
      }
      case 404: {
        throw new Error(
          "The verification code you provided has expired. Please resend the verification text below."
        );
      }
    }

    return data;
  } catch (error) {
    if (error instanceof Error) {
      console.error(error);
    } else {
      console.error(new Error("An unknown error occurred while logging in"));
    }
    throw new Error(
      "There was a problem verifying your phone number. If the problem persists, please contact us."
    );
  }
}

export const getToken = () => {
  return localStorage.getItem(userTokenKey);
};
export const setToken = (token: string) => {
  localStorage.setItem(userTokenKey, token);
};

export const clearToken = () => {
  localStorage.removeItem(userTokenKey);
};
